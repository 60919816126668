exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-events-04-03-2021-js": () => import("./../../../src/pages/events/04-03-2021.js" /* webpackChunkName: "component---src-pages-events-04-03-2021-js" */),
  "component---src-pages-events-20-05-2021-js": () => import("./../../../src/pages/events/20-05-2021.js" /* webpackChunkName: "component---src-pages-events-20-05-2021-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-track-record-js": () => import("./../../../src/pages/track-record.js" /* webpackChunkName: "component---src-pages-track-record-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

